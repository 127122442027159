import React, { Component } from "react";
import { PageContent, Table } from "../../ui";
import { Header, FilterTable, OrderInfo } from "../../features";
import { RouteComponentProps } from "react-router-dom";
import classNames from "./style.module.scss";
import ReactPaginate from "react-paginate";
import * as request from "../../requests";
import Loader from "react-loader";
import moment from "moment";

interface State {
  openOrder: boolean;
  arrayItems: Array<any>;
  loader: boolean;
  last_page: number;
  current_page: number;
  openId: number;
  statuses: Array<any>;
  date: string;
  search: string;
}
const columnsOrders = [
  "№",
  "Дата доставки",
  "Способ доставки",
  "Способ оплаты",
  "Адрес доставки",
  "Телефон для связи",
  "Статус",
];
class MainPage extends React.Component<RouteComponentProps<any>, any> {
  state: State = {
    openOrder: false,
    arrayItems: [],
    loader: true,
    last_page: 1,
    openId: 0,
    current_page: 1,
    statuses: [],
    date:
      moment().startOf("day").format("YYYY-MM-DD HH:mm") +
      ";" +
      moment().endOf("day").format("YYYY-MM-DD HH:mm"),
    search: "",
  };

  componentDidMount() {
    let token = localStorage.getItem("token_penny");
    if (!token) {
      this.props.history.replace("/auth");
    } else {
      request
        .getOrders(1, this.state.date)
        .then((json: any) => {
          console.log(json);
          this.setState({
            arrayItems: json.data.data,
            last_page: json.data.last_page,
          });
        })
        .catch((error: any) => {
          if (error.status == 401) {
            this.props.history.replace("/auth");
          }
        })
        .finally(() => this.setState({ loader: false }));
      request.getStatuses().then((json: any) => {
        this.setState({ statuses: json.data });
      });
    }
  }

  openOrder = (id: number) => {
    this.setState({ openOrder: true, openId: id });
  };

  closeOrder = () => {
    this.setState({ openOrder: false });
  };

  changePage = (data: any) => {
    this.setState({ loader: true }, () => {
      let page = data.selected + 1;
      request
        .getOrders(page, this.state.date)
        .then((json: any) => {
          this.setState({
            arrayItems: json.data.data,
            last_page: json.data.last_page,
            current_page: page,
          });
        })
        .finally(() => this.setState({ loader: false }));
    });
  };

  exit = () => this.props.history.replace("/auth");

  updateStatus = () => {
    this.setState({ loader: true }, () => {
      let page = this.state.current_page;
      request.getOrders(page,this.state.date).then((json: any) => {
        this.setState({
          arrayItems: json.data.data,
          last_page: json.data.last_page,
          loader: false,
        });
      });
    });
  };

  changeDate = (date_start: string, date_end: string) => {
    console.log(date_start, date_end);
    this.setState({ loader: true, current_page: 1 }, () => {
      let date =
        date_start +
        ";" +
        (date_end.length == 0 ? moment().format("YYYY-MM-DD") : date_end);
      this.setState({ date }, () => this.getOrders());
    });
  };

  onSearch = (value: string) => {
    this.setState({ search: value, current_page: 1 }, () => this.getOrders());
  };

  getOrders = () => {
    let { last_page, current_page, date, search } = this.state;
    this.setState({ loader: true }, () => {
      if (current_page == 1 || current_page != last_page)
        request
          .getOrders(current_page, date, search)
          .then((json: any) => {
            this.setState({
              arrayItems: json.data.data,
              loader: false,
              last_page: json.data.last_page,
            });
          })
          .catch((error: any) => {
            if (error.status == 401) {
              this.props.history.replace("/auth");
            }
          });
      else this.setState({ loader: false });
    });
  };

  saveFile = (id: number) => {
    request.getFile(id).then((json:any) => {
      // console.log(json);
      window.open(json.url, '_blank')
    });
  };

  render() {
    let {
      openOrder,
      arrayItems,
      loader,
      last_page,
      openId,
      current_page,
      statuses,
    } = this.state;
    return (
      <PageContent>
        <Header exit={this.exit}></Header>
        <FilterTable
          title="Заказы"
          changeDate={this.changeDate}
          onSearch={this.onSearch}
          update={this.getOrders}
        ></FilterTable>
        <Loader loaded={!loader} lines={8} length={5} radius={15}>
          <Table
            columns={columnsOrders}
            type="orders"
            arrayItems={arrayItems}
            open={this.openOrder}
            statuses={statuses}
            updateStatus={this.updateStatus}
            saveFile={this.saveFile}
          ></Table>
        </Loader>
        {!loader && (
          <ReactPaginate
            previousLabel={""}
            nextLabel={""}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={last_page}
            marginPagesDisplayed={1}
            pageRangeDisplayed={5}
            containerClassName={"pagination"}
            activeClassName={"active"}
            onPageChange={this.changePage}
            forcePage={current_page - 1}
          />
        )}
        {openOrder && <OrderInfo onClose={this.closeOrder} id={openId} />}
      </PageContent>
    );
  }
}

export default MainPage;
