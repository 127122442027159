import React, { Component } from "react";
import { PageContent, Table } from "../../../ui";
import { Header, FilterTable, Promotion } from "../../../features";
import { RouteComponentProps } from "react-router-dom";
import ReactPaginate from "react-paginate";
import * as request from "../../../requests";
import Loader from "react-loader";
import classNames from "../style.module.scss";
import moment from "moment";

interface State {
  arrayItems: Array<any>;
  openPromo: boolean;
  openID: number;
  last_page: number;
  current_page: number;
  loader: boolean;
  date: string;
  search: string;
}

const columnsPromo = ["№", "Дата начала", "Дата окончания", "Название акции"];
class PromotionsPage extends React.Component<RouteComponentProps<any>, any> {
  state: State = {
    arrayItems: [],
    openPromo: false,
    openID: 0,
    last_page: 1,
    current_page: 1,
    loader: false,
    date:
      moment().startOf("day").format("YYYY-MM-DD HH:mm") +
      ";" +
      moment().endOf("day").format("YYYY-MM-DD HH:mm"),
    search: "",
  };

  componentDidMount() {
    let token = localStorage.getItem("token_penny");
    let user_id = localStorage.getItem("user_id_penny");
    if (!token) {
      this.props.history.replace("/auth");
    } else if (user_id != "1") {
      localStorage.setItem("user_id_penny", "0");
      this.props.history.replace("/");
    } else this.getPromotions();
    // request.getStatuses().then((json) => {
    //   console.log(json);
    // });
  }

  getPromotions = () => {
    let { last_page, current_page, date, search } = this.state;
    this.setState({ loader: true }, () => {
      if (current_page == 1 || current_page != last_page)
        request
          .getPromotions(current_page, date, search)
          .then((json: any) => {
            this.setState({ arrayItems: json.data.data, loader: false });
          })
          .catch((error: any) => {
            if (error.status == 401) {
              this.props.history.replace("/auth");
            }
          });
      else this.setState({ loader: false });
    });
  };

  openPromo = (id: number | null) => {
    this.setState({ openPromo: true, openID: id });
  };

  onSearch = (value: string) => {
    this.setState({ search: value, current_page: 1 }, () =>
      this.getPromotions()
    );
  };

  closeUpdates = () => {
    this.setState({ openPromo: false });
    this.getPromotions();
  };

  closePromo = () => {
    this.setState({ openPromo: false });
    this.getPromotions();
  };

  handlePageClick = (data: any) => {
    this.setState({ loader: true }, () => {
      let page = data.selected + 1;
      this.setState(
        {
          current_page: page,
        },
        () => {
          this.getPromotions();
        }
      );
    });
  };

  changeDate = (date_start: string, date_end: string) => {
    console.log(date_start, date_end);
    this.setState({ loader: true, current_page: 1 }, () => {
      let date =
        date_start +
        ";" +
        (date_end.length == 0 ? moment().format("YYYY-MM-DD") : date_end);
      this.setState({ date }, () => this.getPromotions());
    });
  };

  exit = () => this.props.history.replace("/auth");
  render() {
    let {
      arrayItems,
      openPromo,
      openID,
      last_page,
      loader,
      current_page,
    } = this.state;
    console.log(loader);
    return (
      <PageContent>
        <Header exit={this.exit}></Header>
        <FilterTable
          title="Акции"
          addPromotion={() => this.openPromo(null)}
          changeDate={this.changeDate}
          onSearch={this.onSearch}
          noDate
        ></FilterTable>
        <Loader loaded={!loader} lines={8} length={5} radius={15}>
          <Table
            columns={columnsPromo}
            type="promotions"
            arrayItems={arrayItems}
            open={this.openPromo}
          />
        </Loader>
        {openPromo && <Promotion id={openID} onClose={this.closePromo} />}
        {!loader && (
          <ReactPaginate
            previousLabel={""}
            nextLabel={""}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={last_page}
            marginPagesDisplayed={1}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
            forcePage={current_page - 1}
          />
        )}
      </PageContent>
    );
  }
}

export default PromotionsPage;
