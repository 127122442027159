import React, { Component, PropTypes } from 'react';
import moment from 'moment';
import { YMaps, Map, Placemark, Clusterer } from "react-yandex-maps";

const key = 'p33ad0f99-e990-4eca-8dda-aafe2e3e6d64 ';

const mapData = {
    center: [45.01628985039873, 41.92791178822518],
    zoom: 10,
};

const coordinates = [
    [55.684758, 37.738521],
    [57.684758, 39.738521]
];


export default class MapView extends Component {
    state = {
        count: 0,
        client_address: ""
    }

    componentDidMount() {
        // this.setState({ count: this.props.markers.length })
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                mapData.center = [position.coords.latitude, position.coords.longitude];
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedAddress != this.props.selectedAddress) {
            if (this.props.selectedAddress.latitude)
                this.map.setCenter([this.props.selectedAddress.latitude.replace(',', '.'), this.props.selectedAddress.longitude.replace(',', '.')], 15)
        }
    }


    clickMarker = (lat, lng, id) => {
        console.log(lat, lng)
        setTimeout(() => {
            this.map.setCenter([lat, lng], 15)
        }, 300);
        this.props.clickMarker(id)
    }

    getAddress = (position) => {
        return new Promise((resolve, reject) => {
            fetch('https://nominatim.openstreetmap.org/reverse?format=json&lat=' + position.lat + '&lon=' + position.lng + '&zoom=18&addressdetails=1')
                .then(response => response.json())
                .then(json => {
                    console.log(json);
                    let display_name = '';
                    if (json.address.country) display_name += json.address.country + '|';
                    if (json.address.state) display_name += json.address.state + '|';
                    if (json.address.city && json.address.city != 'Москва') display_name += json.address.city + '|';
                    if (json.address.road) display_name += json.address.road + '|';
                    if (json.address.house_number) display_name += json.address.house_number;

                    json.display_name = display_name.split('|').join(', ');
                    resolve(json);
                }).catch(error => reject(error))
        })
    }

    setCenter = (position) => {
        this.map.setView([position[0], position[1]]);
    }

    clickMap = () => {
        console.log(123)
    }

    render() {
        // console.log(123)
        return (
            <React.Fragment>
                <YMaps query={{ apikey: key }} >
                    <Map defaultState={mapData}
                        width="100vw" height="100vh"
                        modules={["templateLayoutFactory", "layout.ImageWithContent"]}
                        onClick={this.clickMap}
                        instanceRef={map => (this.map = map)}
                    >
                        <Clusterer
                            options={{
                                preset: 'islands#invertedRedClusterIcons',
                                groupByCoordinates: false,
                                clusterDisableClickZoom: true,
                                clusterHideIconOnBalloonOpen: false,
                                geoObjectHideIconOnBalloonOpen: false,
                            }}
                        >
                            {this.props.markers.map((item) => {
                                let lat = item.latitude.replace(',', '.');
                                let lng = item.longitude.replace(',', '.');
                                const placeMark = {
                                    geometry: [lat, lng],
                                    properties: {
                                        /* hintContent: 'Это хинт', */
                                        balloonContent: '<div class="title">'
                                            + item.name + '</div>' +
                                            '<div class="flex">' +
                                            '<div class="block_info_address"><div class="sub_title">' +
                                            'Время работы' +
                                            '</div>' +
                                            '<div class="value">' + "Пн-Пт " +
                                            moment(item.timeopen, 'HH:mm:ss').format('HH:mm') + ' - ' + moment(item.timeclose, 'HH:mm:ss').format('HH:mm') +
                                            '</div>' +
                                            '</div>' +
                                            '<div class="block_info_address"><div class="sub_title">' +
                                            'Телефон' +
                                            '</div>' +
                                            '<div class="value">' +
                                            item.phone +
                                            '</div>' +
                                            '</div>' +
                                            '</div>'
                                    },
                                    options: {
                                        iconLayout: 'default#imageWithContent',
                                        iconImageHref: require("../../assets/images/marker.svg"),
                                       iconImageSize: [40, 40],
                                       iconImageOffset: [-24, -24]
                                        /* preset: 'islands#redDotIcon' */



                                    },
                                    modules:
                                        ['geoObject.addon.balloon']

                                }
                                return (
                                    <Placemark {...placeMark} onClick={() => this.clickMarker(lat, lng, item.id)}
                                    />
                                )
                            })}
                        </Clusterer>
                    </Map>
                </YMaps>
            </React.Fragment>
        );
    }
}

MapView.defaultProps = {
    changeMarker: true,
    onChangeAddress: () => { }
}
