import React, { Component } from "react";
import { Page } from "./ui";

import "./assets/css/App.css";
import "./assets/css/colors.css";
import 'react-toastify/dist/ReactToastify.css';

import { MainRoute } from "./routes";
import { ToastContainer } from "react-toastify";

class App extends Component {
  
  render() {
    return (
      <React.Fragment>
        <Page>
          <MainRoute />
          <ToastContainer/>
        </Page>
      </React.Fragment>
    );
  }
}

export default App;
