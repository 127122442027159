import React from "react";
import classNames from "./style.module.scss";

interface Props {
  item: any;
  openPromo: any;
}

const RowPromotion = ({ item, openPromo }: Props) => {
  return (
    <tr className={classNames.border_bottom} onClick={openPromo}>
      <td>{item.id}</td>
      <td>{item.date_from}</td>
      <td>{item.date_till}</td>
      <td style={{width:'60%'}}>{item.name}</td>
    </tr>
  );
};

export default RowPromotion;
