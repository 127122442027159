import React, { ReactNode } from 'react';
import classNames from './style.module.scss';

interface Props {
    children: ReactNode;
}

const Page = ({ children }: Props) => {
    return (
        <div className={classNames.container}>
            {children}
        </div>
    )
}

export default Page;