import React, { Component } from "react";
import classNames from "./style.module.scss";
import BasketItem from "../Basket/BasketItem";
import { MapModal } from "../MapModal";
import CourierModal from "./CourierModal";
import BasketAddModal from "./BasketAddModal/BasketAddModal";
import { Button, RadioButton } from "../../ui";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";
import InputMask from "react-input-mask";
import * as request from "../../requests";
import Loader from "react-loader";
import moment from "moment";

interface Props {
  // onCreate: any;
  onClose: any;
  id: number;
  // openMap: any;
}

interface State {
  change: boolean;
  openMap: boolean;
  openCourier: boolean;
  addBasket: boolean;
  loader: boolean;
  delivery_date: string;
  client_phone_number: string;
  client_email: string;
  client_bonus_card: string;
  client_name: string;
  id: number;
  assortments: Array<any>;
  sum: number;
  status: any;
  delivery_type: string;
  courier: any;
  payment_type: any;
  created_at: string;
  courier_id: number;
  client_address: string;
  store_id: string;
  store_name: string;
  original_data: any;
  disabledItem: boolean;
  productsCount: number;
  store: any;
}

class OrderInfo extends Component<Props> {
  state: State = {
    change: false,
    openMap: false,
    openCourier: false,
    addBasket: false,
    loader: true,
    delivery_date: "",
    client_phone_number: "",
    client_email: "",
    client_bonus_card: "",
    client_name: "",
    id: 0,
    assortments: [],
    sum: 0,
    status: {},
    delivery_type: "",
    courier: "",
    courier_id: 0,
    client_address: "",
    payment_type: {},
    created_at: "",
    store_id: "",
    store_name: "",
    original_data: {},
    disabledItem: false,
    productsCount: 0,
    store: {},
  };

  componentDidMount() {
    this.getOrder();
  }

  getOrder = () => {
    request
      .getOrder(this.props.id)
      .then((json: any) => {
        let assortments = this.state.assortments;
        let productsCount = 0;
        assortments = json.data.assortments.map((item: any) => {
          let newProduct = item;
          productsCount += newProduct.quantity;
          if (item.contents)
            item.contents.forEach((element: any) => {
              if (element.type == "reference" && newProduct.img.length == 0) {
                newProduct.img = element.url;
              }
            });
          return newProduct;
        });
        this.setState(
          {
            ...json.data,
            original_data: json.data,
            store_id: json.data.store ? json.data.store.id : "",
            store_name: json.data.store ? json.data.store.address : "",
            assortments,
            productsCount,
          },
          () => {
            console.log(this.state);
          }
        );
      })
      .finally(() => {
        this.setState({ loader: false });
      });
  };

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, false);
  }

  componentWillMount() {
    document.addEventListener("click", this.handleClickOutside, false);
  }

  handleClickOutside = (event: any) => {
    // console.log(123);
    let block_basket = document.getElementsByClassName("block_basket")[0];
    // console.log(block_basket);
    if (!event.path.includes(block_basket)) this.setState({ addBasket: false });
  };

  openDelivery = () => {};

  changeOrder = () => this.setState({ change: true });

  cancelChange = () => {
    this.getOrder();
    this.setState({ change: false });
  };

  saveChange = () => {
    this.setState({ loader: true }, () => {
      let {
        delivery_date,
        client_phone_number,
        client_email,
        client_bonus_card,
        client_name,
        id,
        assortments,
        sum,
        status,
        delivery_type,
        courier,
        client_address,
        payment_type,
        store_name,
        store_id,
      } = this.state;
      let params = {
        delivery_date,
        client_phone_number,
        client_email,
        payment_type_id: payment_type.id,
        client_bonus_card,
        client_name,
        id,
        assortments: assortments.map((item) => {
          let newItem = {
            id: item.product.id,
            quantity: item.quantity,
            price: item.price,
          };
          return newItem;
        }),
        sum,
        status: {
          status_id: status.id,
          description: "",
        },
        delivery_type,
        courier,
        client_address,
        store_id: store_id,
      };
      console.log(params);
      request
        .updateOrder(this.props.id, params)
        .then(() => {
          // this.props.onClose();
          this.setState({ change: false });
        })
        .finally(() => this.setState({ loader: false }));
    });
  };

  cancelOrder = () => {
    this.setState({ loader: true }, () => {
      let { original_data } = this.state;
      let params = {
        delivery_date: original_data.delivery_date,
        client_phone_number: original_data.client_phone_number,
        client_email: original_data.client_email,
        payment_type_id: original_data.payment_type.id,
        client_bonus_card: original_data.client_bonus_card,
        client_name: original_data.client_name,
        id: original_data.id,
        assortments: original_data.assortments.map((item: any) => {
          let newItem = {
            id: item.product.id,
            quantity: item.quantity,
            price: item.price
          };
          return newItem;
        }),
        sum: original_data.sum,
        status: {
          status_id: 7,
          description: "",
        },
        delivery_type: original_data.delivery_type,
        courier: original_data.courier,
        client_address: original_data.client_address,
        store_id: original_data.store_id,
      };
      console.log(params);
      request
        .updateOrder(this.props.id, params)
        .then(() => {
          // this.props.onClose();
          this.setState({ change: false });
        })
        .finally(() => {
          this.setState({ loader: false });
          this.props.onClose();
        });
    });
  };

  openMap = () => this.setState({ openMap: true });

  changePeriod = (date: Date) => {
    this.setState({ delivery_date: moment(date).format("YYYY-MM-DD") });
  };

  closeMap = () => this.setState({ openMap: false });

  openCourier = () => this.setState({ openCourier: true });

  closeCourier = () => this.setState({ openCourier: false });

  onAddBasket = () => {
    this.setState({ addBasket: true });
  };

  addCount = (id: number) => {
    let assortments = this.state.assortments;
    assortments[id].quantity++;
    let sum = +this.state.sum + +assortments[id].price;
    let productsCount = this.state.productsCount + 1;
    this.setState({ assortments, sum:sum.toFixed(2), productsCount });
    this.checkOrder();
  };

  subCount = (id: number) => {
    let assortments = this.state.assortments;
    if (assortments[id].quantity > 1) {
      assortments[id].quantity--;
      let sum = +this.state.sum - +assortments[id].price;
      let productsCount = this.state.productsCount - 1;
      this.setState({ assortments, sum:sum.toFixed(2), productsCount });
      this.checkOrder();
    }
  };

  deleteProduct = (id: number) => {
    let assortments = this.state.assortments;
    let sum =
      +this.state.sum - assortments[id].price * assortments[id].quantity;
    let productsCount = this.state.productsCount - assortments[id].quantity;
    assortments.splice(id, 1);
    this.setState({ assortments, sum:sum.toFixed(2), productsCount });

    this.checkOrder();
  };

  checkOrder = () => {
    let index = this.state.assortments.findIndex(
      (item) => item.quantity > item.quantity_count
    );

    this.setState({ disabledItem: index == -1 ? false : true });
  };

  changeInput = (event: React.FormEvent<HTMLInputElement>) => {
    let field = event.currentTarget.id;
    let value = event.currentTarget.value;
    if (field == "phone") this.setState({ client_phone_number: value });
    else if (field == "email") this.setState({ client_email: value });
    else if (field == "name") this.setState({ client_name: value });
  };

  choiceDelivery = (type: string) => {
    this.setState({ delivery_type: type }, () => console.log(this.state));
  };

  choiceAddress = (type: string, address: any) => {
    if (type == "shop")
      this.setState(
        {
          client_address: "",
          store_id: address.id,
          store_name: address.name + ", " + address.address,
          disabledItem: false,
        },
        () => {
          setTimeout(() => {
            this.setState({ visible_map: false });
            this.getBasket(address.id);
          }, 200);
        }
      );
    else {
      this.setState({
        client_address: address,
        store_id: 0,
        store_name: "",
      });
    }
  };

  getBasket = (shop?: string) => {
    let basket = this.state.assortments;

    let params = {
      assortments: basket.map((item: any) => item.product.id),
      store: "",
    };

    if (shop) params.store = shop;

    request
      .getBaskets(params)
      .then((json: any) => {
        let assortments = json.data;

        let totalSum = 0;
        let totalCount = 0;
        assortments.map((item: any, index: number) => {
          let newProduct = basket[index];
          newProduct.quantity = basket[index].quantity;
          totalSum += newProduct.count * newProduct.price;
          totalCount += newProduct.count;
          if (item.quantity.length == 0) this.setState({ disabledItem: true });
          if (shop) {
            newProduct.quantity_count =
              item.quantity.length != 0 ? item.quantity[0].quantity : 0;
            if (newProduct.quantity_count < newProduct.quantity)
              this.setState({ disabledItem: true });
          }
          newProduct.img = "";
          if (item.contents)
            item.contents.forEach((element: any) => {
              if (element.type == "reference" && newProduct.img.length == 0) {
                newProduct.img = element.url;
              }
            });
          return newProduct;
        });
        console.log(assortments, basket);
        this.setState({ assortments: basket, totalSum, totalCount });
      })
      .finally(() => this.setState({ loading: false, openMap: false }));
  };

  addItem = (item: any) => {
    console.log(item);
    let assortments = this.state.assortments;
    let index = assortments.findIndex((product: any) => item.id == product.id);
    console.log(index);
    if (index == -1) {
      assortments.push({
        id: item.id,
        price: item.price,
        quantity: 1,
        product: item,
      });
    } else {
      assortments[index].quantity += 1;
    }
    console.log(assortments);
    this.setState({ assortments, addBasket: false });
  };

  render() {
    let {
      change,
      openMap,
      openCourier,
      addBasket,
      id,
      loader,
      assortments,
      sum,
      status,
      delivery_type,
      courier,
      client_email,
      client_name,
      client_address,
      delivery_date,
      client_phone_number,
      payment_type,
      created_at,
      store_name,
      store_id,
      disabledItem,
      productsCount,
      store,
    } = this.state;
    return (
      <div className={classNames.container}>
        <div className={classNames.ordering}>
          <Loader loaded={!loader} lines={8} length={5} radius={15}>
            <div className={classNames.flex + " " + classNames.top}>
              <div className={classNames.title_top}>
                {"Заказ № " +
                  (id == 0 ? "" : id) +
                  (change ? "- Редактирование" : "")}
              </div>
              <div
                className={classNames.close}
                onClick={this.props.onClose}
              ></div>
            </div>
            <div className={classNames.flex + " " + classNames.block_main}>
              <div className={classNames.left_block}>
                <div className={classNames.flex + " block_basket"}>
                  <div className={classNames.title} style={{ marginRight: 10 }}>
                    Корзина
                  </div>
                  {change && (
                    <a onClick={this.onAddBasket}>
                      Добавить товар
                      {addBasket && <BasketAddModal addItem={this.addItem} />}
                    </a>
                  )}
                </div>
                <div className={classNames.basket_items}>
                  {assortments.map((item: any, index: number) => {
                    return (
                      <BasketItem
                        item={item}
                        subCount={() => this.subCount(index)}
                        addCount={() => this.addCount(index)}
                        deleteProduct={() => this.deleteProduct(index)}
                        change={change}
                        img={item.img}
                        quantity_count={item.quantity_count}
                        quantity={item.quantity_count == 0}
                      />
                    );
                  })}
                </div>
              </div>
              <div className={classNames.right_block}>
                <div className={classNames.title}>Подробности заказа</div>
                <div
                  className={
                    classNames.flex + " " + classNames.block_information
                  }
                >
                  <div className={classNames.item_information}>
                    <div className="item_title">Товаров</div>
                    <div className={classNames.item_value}>{productsCount}</div>
                  </div>
                  <div className={classNames.item_information}>
                    <div className="item_title">Вес, кг</div>
                    <div className={classNames.item_value}>-</div>
                  </div>
                  <div className={classNames.item_information}>
                    <div className="item_title">Сумма заказа</div>
                    <div className={classNames.item_value}>{sum} ₽</div>
                  </div>
                  <div className={classNames.item_information}>
                    <div className="item_title">Статус</div>
                    <div className={classNames.item_value}>{status.name}</div>
                  </div>
                </div>

                {change ? (
                  <div>
                    <div
                      className={
                        classNames.flex + " " + classNames.block_information
                      }
                    >
                      <div className={classNames.item_information}>
                        <div className="item_title">Способ доставки</div>
                        <div className={classNames.radio_groupe}>
                          <RadioButton
                            title="Самовывоз"
                            onclick={() => this.choiceDelivery("pickup")}
                            checked={delivery_type == "pickup"}
                          />
                          <RadioButton
                            title="Курьером"
                            onclick={() => this.choiceDelivery("courier")}
                            checked={delivery_type != "pickup"}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        classNames.flex +
                        " " +
                        classNames.block_information +
                        " " +
                        classNames.inputs_block +
                        " " +
                        classNames.address_name
                      }
                    >
                      <div
                        className={classNames.block_information}
                        style={{ marginRight: 15, marginTop: 16 }}
                      >
                        <div className={classNames.item_title}>
                          Адрес магазина <a onClick={this.openMap}>Изменить</a>
                        </div>
                        <div>{store_name}</div>
                      </div>
                      <div
                        className={
                          classNames.item_information +
                          " " +
                          classNames.nameCustomer
                        }
                      >
                        <div className={classNames.item_title}>
                          Имя заказчика
                        </div>
                        <input
                          id="name"
                          value={client_name}
                          onChange={this.changeInput}
                        ></input>
                      </div>
                    </div>

                    <div
                      className={
                        classNames.flex +
                        " " +
                        classNames.block_information +
                        " " +
                        classNames.inputs_block
                      }
                    >
                      <div className={classNames.item_information}>
                        <div className="item_title">Дата доставки</div>
                        <DatePicker
                          onChange={this.changePeriod}
                          locale={ru}
                          // placeholderText="Выберите дату"
                          dateFormat="DD.MM.YYYY"
                          value={moment(delivery_date).format("DD.MM.YYYY")}
                          disabled
                        />
                      </div>
                      <div className={classNames.item_information}>
                        <div className="item_title">Телефон</div>
                        <InputMask
                          mask="+7 (999) 999-99-99"
                          maskChar=" "
                          value={client_phone_number}
                          id="phone"
                          onChange={this.changeInput}
                        />
                      </div>
                      <div className={classNames.item_information}>
                        <div className="item_title">Почта</div>
                        <input
                          id="email"
                          value={client_email}
                          onChange={this.changeInput}
                        ></input>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={classNames.block_information}>
                    <div className={classNames.flex_info}>
                      <div className={classNames.title_info}>
                        Дата оформления
                      </div>
                      <div className={classNames.value_info}>
                        {moment(created_at).format("DD.MM.YYYY")}
                      </div>
                    </div>
                    <div className={classNames.flex_info}>
                      <div className={classNames.title_info}>
                        Способ доставки
                      </div>
                      <div className={classNames.value_info}>
                        {delivery_type == "pickup"
                          ? "Самовывоз"
                          : delivery_type == "courier"
                          ? "Курьером"
                          : ""}
                      </div>
                    </div>
                    {delivery_type == "courier" && (
                      <div className={classNames.flex_info}>
                        <div className={classNames.title_info}>Имя курьера</div>
                        <div className={classNames.value_info}>
                          {/* {courier.name ? courier.name : "-"} */}
                        </div>
                      </div>
                    )}
                    {delivery_type == "courier" && (
                      <div className={classNames.flex_info}>
                        <div className={classNames.title_info}>
                          Телефон курьера
                        </div>
                        <div className={classNames.value_info}>
                          {/* {courier.phone ? courier.phone : "-"} */}
                        </div>
                      </div>
                    )}
                    <div className={classNames.flex_info}>
                      <div className={classNames.title_info}>Способ оплаты</div>
                      <div className={classNames.value_info}>
                        {" "}
                        {payment_type.name}
                      </div>
                    </div>
                    <div className={classNames.flex_info}>
                      <div className={classNames.title_info}>
                        Адрес{" "}
                        {delivery_type == "pickup" ? "магазина" : "доставки"}
                      </div>
                      <div className={classNames.value_info}>
                        {delivery_type == "pickup"
                          ? store.address
                          : client_address}
                      </div>
                    </div>
                    <div className={classNames.flex_info}>
                      <div className={classNames.title_info}>Дата доставки</div>
                      <div className={classNames.value_info}>
                        {moment(delivery_date).format("DD.MM.YYYY")}
                      </div>
                    </div>
                    <div className={classNames.flex_info}>
                      <div className={classNames.title_info}>
                        Телефон для связи
                      </div>
                      <div className={classNames.value_info}>
                        {client_phone_number}
                      </div>
                    </div>
                    <div className={classNames.flex_info}>
                      <div className={classNames.title_info}>Почта</div>
                      <div className={classNames.value_info}>
                        {" "}
                        {client_email}
                      </div>
                    </div>
                    <div className={classNames.flex_info}>
                      <div className={classNames.title_info}>Имя заказчика</div>
                      <div className={classNames.value_info}>
                        {" "}
                        {client_name}
                      </div>
                    </div>
                    {/* <div className={classNames.flex_info}>
                    <div className={classNames.title_info}>Дата доставки</div>
                    <div className={classNames.value_info}> 21.04.2020</div>
                  </div> */}
                  </div>
                )}

                {/* <CheckBox title="Согласен на рассылку новостей" /> */}
                <div
                  className={classNames.flex + " " + classNames.block_bottom}
                >
                  {((delivery_type == "courier" && !change && status.id <= 2) ||
                    change) && (
                    <Button
                      title={
                        change ? "Сохранить изменения" : "Передать курьеру"
                      }
                      onclick={change ? this.saveChange : this.openCourier}
                      type="yellow"
                      disabled={change && disabledItem}
                    />
                  )}
                  {status.id <= 2 && (
                    <Button
                      title={change ? "Отмена" : "Изменить"}
                      onclick={change ? this.cancelChange : this.changeOrder}
                      type="white"
                    />
                  )}
                  {!change && status.id <= 2 && (
                    <Button
                      title="Отменить заказ"
                      onclick={this.cancelOrder}
                      type="red"
                    />
                  )}
                </div>
              </div>
            </div>
          </Loader>
        </div>
        {openMap && (
          <MapModal
            onClose={this.closeMap}
            choiceAddress={this.choiceAddress}
          />
        )}
        {openCourier && (
          <CourierModal
            onClose={this.closeCourier}
            store_id={store_id}
            id={this.props.id}
          />
        )}
      </div>
    );
  }
}

export default OrderInfo;
