import React, { createRef } from "react";
import classNames from "./style.module.scss";
import { render } from "@testing-library/react";

interface Props {
  onClickSearch: any;
  onChange: any;
  value: string;
}

class Searchbar extends React.Component<Props> {
  // public myRef = createRef<HTMLInputElement>();

  render() {
    let { onClickSearch, onChange, value } = this.props;

    return (
      <div className={classNames.container}>
        <div className={classNames.searchbar_container}>
          <input
            placeholder={"Что вы ищите?"}
            onChange={onChange}
            className={classNames.searchbar}
            // ref={(ref) => this.myRef}
            // onBlur={onClickSearch}
            onFocus={onChange}
          />
          <button
            onClick={onClickSearch}
            value={value}
            className={classNames.button}
          >
            Поиск
          </button>
        </div>
      </div>
    );
  }
}

export default Searchbar;
