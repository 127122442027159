import React, { Component } from "react";
import classNames from "./style.module.scss";
import BasketItem from "../Basket/BasketItem";
import { MapModal } from "../MapModal";
import { Button, RadioButton } from "../../ui";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";
import * as request from "../../requests";
import Loader from "react-loader";
import moment from "moment";

interface Props {
  // onCreate: any;
  onClose: any;
  id?: Number | null;
  // openMap: any;
}

interface State {
  change: boolean;
  action_button_name: string;
  action_url: string;
  date_from: string;
  date_till: string;
  description: string;
  image_url: string;
  name: string;
  id: number;
  loader: boolean;
}

class Promotion extends Component<Props> {
  state: State = {
    change: false,
    action_url: "",
    date_from: "",
    date_till: "",
    description: "",
    image_url: "",
    name: "",
    action_button_name: "",
    id: 0,
    loader: false,
  };

  componentDidMount() {
    if (this.props.id !== null) this.getPromotion();
  }

  getPromotion = () => {
    this.setState({ loader: true }, () => {
      request
        .getPromotion(this.props.id!)
        .then((json: any) => {
          this.setState({
            name: json.data.name,
            action_button_name: json.data.action_button_name,
            action_url: json.data.action_url,
            date_from: json.data.date_from,
            date_till: json.data.date_till,
            description: json.data.description,
            image_url: json.data.image_url,
            id: json.data.id,
          });
        })
        .finally(() => this.setState({ loader: false }));
    });
  };
  saveChanges = () => {
    this.setState({ loader: true }, () => {
      let {
        name,
        action_button_name,
        action_url,
        date_from,
        date_till,
        description,
        image_url,
      } = this.state;
      let params = {
        name: name,
        action_button_name: action_button_name,
        action_url: action_url,
        date_from: date_from,
        date_till: date_till,
        description: description,
        image_url: image_url,
      };
      request.updatePromotion(this.props.id!, params)
        .then(() => {
          this.props.onClose();
        })
        .finally(() => {
          this.setState({ loader: false });
        })
    });
  };

  createPromo = () => {
    this.setState({ loader: true }, () => {
      let {
        name,
        action_button_name,
        action_url,
        date_from,
        date_till,
        description,
        image_url,
      } = this.state;
      let params = {
        name: name,
        action_button_name: action_button_name,
        action_url: action_url,
        date_from: date_from,
        date_till: date_till,
        description: description,
        image_url: image_url,
      };
      // console.log(image_url)
      request.createPromotion(params)
        .then(() => {
          this.props.onClose();
        })
        .finally(() => {
          this.setState({ loader: false });
        })
    });
  };

  deletePromo = () => {
    this.setState({ loader: true }, () => {
      request.deletePromotion(this.props.id!)
        .then(() => {
          this.props.onClose();
        })
        .finally(() => {
          this.setState({ loader: false });
        })
    });
  };
  changeFile = (event: React.FormEvent<HTMLInputElement>) => {
    let files = event.currentTarget.files;
    this.setState({ loader: true }, () => {
      console.log(files?.item(0));
      let form = new FormData();
      form.append("image", files?.item(0)!);
      setTimeout(() => {
        console.log(form);


        request.imageUpload(form).then((json: any) => {
          this.setState({ image_url: json.data.img, loader: false });
        });
      }, 200);
    });
  };

  changePeriodStart = (date: Date) => {
    this.setState({ date_from: moment(date).format("DD.MM.YYYY") });
  };

  changePeriodEnd = (date: Date) => {
    this.setState({ date_till: moment(date).format("DD.MM.YYYY") });
  };

  changeDescription = (event: React.FormEvent<HTMLTextAreaElement>) => {
    this.setState({ description: event.currentTarget.value });
  };

  changeInput = (event: React.FormEvent<HTMLInputElement>) => {
    let field = event.currentTarget.id;
    let value = event.currentTarget.value;
    console.log(field);
    if (field == "name") this.setState({ name: value });
    else if (field == "action_button_name")
      this.setState({ action_button_name: value });
    else if (field == "action_url") this.setState({ action_url: value });
  };

  deleteImg = () => {
    let url = this.state.image_url;
    this.setState({ image_url: "" });
    let params = { image: url };
    request.deleteImage(params).then((json) => console.log(json));
  };

  replaceImg = () => {
    this.setState({ image_url: "" });
  };

  render() {
    let {
      change,
      name,
      action_button_name,
      action_url,
      date_from,
      date_till,
      description,
      image_url,
      id,
      loader,
    } = this.state;
    return (
      <div className={classNames.container}>
        <Loader loaded={!loader} lines={8} length={5} radius={15} />
        <div className={classNames.promotion}>
          <div className={loader ? classNames.container_load : ""}>
            <div className={classNames.flex + " " + classNames.top}>
              {id != 0 && (
                <div className={classNames.title_top}>
                  {"Акция № " + id + " — " + name}
                </div>
              )}
              <div
                className={classNames.close}
                onClick={this.props.onClose}
              ></div>
            </div>
            <div className={classNames.flex + " " + classNames.block_main}>
              <div className={classNames.left_block}>
                <div className={classNames.item_information}>
                  <div className="item_title">Название акции</div>
                  <input
                    id="name"
                    value={name}
                    onChange={this.changeInput}
                  ></input>
                </div>
                <div className={classNames.item_information}>
                  <div className="item_title">Описание</div>
                  <textarea
                    value={description}
                    onChange={this.changeDescription}
                  ></textarea>
                </div>
              </div>
              <div className={classNames.right_block}>
                <div className={classNames.flex}>
                  <div className={classNames.item_information}>
                    <div className="item_title">Начало действия акции</div>
                    <DatePicker
                      onChange={this.changePeriodStart}
                      locale={ru}
                      dateFormat="dd MMMM yyyy"
                      value={date_from}
                    />
                  </div>
                  <div className={classNames.item_information}>
                    <div className="item_title">Окончание действия акции</div>
                    <DatePicker
                      onChange={this.changePeriodEnd}
                      locale={ru}
                      dateFormat="dd MMMM yyyy"
                      value={date_till}
                    />
                  </div>
                </div>
                <div className={classNames.flex}>
                  <div className={classNames.item_information}>
                    <div className="item_title">Ссылка для кнопки</div>
                    <input
                      id="action_url"
                      value={action_url}
                      onChange={this.changeInput}
                    ></input>
                  </div>
                  <div className={classNames.item_information}>
                    <div className="item_title">Текст кнопки</div>
                    <input
                      id="action_button_name"
                      value={action_button_name}
                      onChange={this.changeInput}
                    ></input>
                  </div>
                </div>
                <div className={classNames.flex}>
                  <div className="item_title">Изображение</div>
                  {image_url != "" && (
                    <div className={classNames.flex}>
                      <div
                        className={classNames.link_promo}
                        onClick={this.replaceImg}
                      >
                        Заменить
                      </div>
                      <div
                        className={classNames.link_promo}
                        onClick={this.deleteImg}
                      >
                        Удалить
                      </div>
                    </div>
                  )}
                </div>
                {image_url == "" ? (
                  <div className={classNames.block_images}>
                    <input
                      className="file-input"
                      type="file"
                      ref="fileInput"
                      onChange={this.changeFile}
                    />
                    <div className={classNames.title}>PNG или JPEG</div>
                    <div className="item_title">1064×300px</div>
                    <div className={classNames.description}>
                      Перетащите изображение или выберете его в{" "}
                      <a>проводнике</a>
                    </div>
                  </div>
                ) : (

                    <div
                      className={classNames.block_images}
                      style={{ backgroundImage: "url(" + image_url + ")" }}
                    ></div>

                  )}
              </div>
            </div>
            <div className={classNames.block_bottom}>
              <Button
                title={id == 0 ? "Создать акцию" : "Сохранить изменения"}
                onclick={id == 0 ? this.createPromo : this.saveChanges}
                type="yellow"
              />
              {this.props.id != 0 && (
                <Button
                  title="Удалить акцию"
                  onclick={this.deletePromo}
                  type="red"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Promotion;
