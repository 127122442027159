import { toast } from "react-toastify";
import { SERVER_NAME } from "../config";

export const request = {
    get: (apiMethod, params) => _requests(apiMethod, params, 'GET'),
    post: (apiMethod, params) => _requests(apiMethod, params, 'POST'),
    put: (apiMethod, params) => _requests(apiMethod, params, 'PUT'),
    delete: (apiMethod, params) => _requests(apiMethod, params, 'DELETE')
}


const TOAST_OPTIONS = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined
}

const _requests = (apiMethod, params, method) => {
    return new Promise((resolve, reject) => {
        const token = window.localStorage.getItem('token_penny');
        const headers = new Headers({ 'Authorization': `Bearer ${token}` });
        let parameters = {
            method,
            headers
        };
        if (apiMethod == 'manager/images' && method == 'POST') {
            if (params) parameters.body = params;
        }
        else {
            headers.append('Content-Type', 'application/json');
            headers.append('Accept', 'application/json');
            if (params) parameters.body = JSON.stringify(params);
        }
        fetch(SERVER_NAME + apiMethod, parameters)
            .then(checkResponse)
            .then(json => {
                console.log('success ->', apiMethod, json);
                if (json && json.errors) {
                    throw json;
                }
                resolve(json);
            })
            .catch((error) => {
                console.log('error ->', apiMethod, error);
                if (error.errors) {
                    Object.keys(error.errors).forEach((key, index) => {
                        error.errors[key].forEach((el, indexEl) => {
                            toast.error(el, TOAST_OPTIONS);
                        })
                    })
                }
                reject(error);
            })
    })
}

const checkResponse = async response => {
    console.log(response.status);
    const { status } = response;
    switch (status) {
        case 200:
        case 201:
            return response.json();
        case 422:
        case 400:
        case 418:
            return response.json();

        case 204: {
            return;
        }
        case 404: {
            throw { status, message: 'Ресурс недоступен' };
        }
        case 401: {
            throw { status, message: 'Не авторизован' }
        }
        default: {
            throw { status, message: 'Произошла ошибка, проверьте подключение или повторите позднее' }
        }
    }
}