import React, { Component } from "react";
import classNames from "./style.module.scss";
import { Button, RadioButton } from "../../ui";
import moment from "moment";
import * as request from "../../requests";
import Loader from "react-loader";
import { load } from "dotenv/types";

interface Props {
  item: any;
  openOrder: any;
  statuses: Array<any>;
  updateStatus: any;
  saveFile: any;
}

interface State {
  openStatuses: boolean;
  statusId: number;
  addClass: boolean;
  newStatus: number;
  loader: boolean;
  newStatuses: Array<any>;
  delivery_method: string;
}

class RowOrder extends Component<Props> {
  state: State = {
    openStatuses: false,
    statusId: 0,
    addClass: false,
    newStatus: 0,
    loader: false,
    newStatuses: [],
    delivery_method: this.props.item.delivery_type,
  };

  componentDidMount() {
    let { delivery_method, newStatuses } = this.state;
    console.log(delivery_method);
    this.props.statuses.forEach((element: any) => {
      if (delivery_method != "pickup" || (element.id != 5 && element.id != 6)) {
        newStatuses.push(element);
      }
    });
    this.setState({
      statusId: this.props.item.status.id,
      newStatus: this.props.item.status.id,
      newStatuses,
    });
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, false);
  }

  componentWillMount() {
    document.addEventListener("click", this.handleClickOutside, false);
  }

  handleClickOutside = (event: any) => {
    // console.log(123);
    let block_statuses = document.getElementsByClassName("block_statuses")[0];
    let status = document.getElementsByClassName("status")[0];
    // console.log(event.path.includes(block_statuses));
    // console.log(event.path.includes(status));
    // console.log(status);
    if (!event.path.includes(block_statuses) && !event.path.includes(status))
      // console.log(block_statuses||status)
      // if(!(block_statuses||status))
      this.setState({ openStatuses: false });
  };

  openStatuses = () => {
    this.setState({ openStatuses: !this.state.openStatuses });
  };
  closeStatuses = () => {
    this.setState({ openStatuses: false });
  };
  choiceStatus = (id: number) => {
    this.setState({ newStatus: id });
  };

  updateStatus = () => {
    this.setState({ loader: true });
    let params = {
      status: {
        status_id: this.state.newStatus,
        description: "",
      },
    };
    console.log(params);
    request.updateOrder(this.props.item.id, params).then(() => {
      this.closeStatuses();
      this.props.updateStatus();
      this.setState({ loader: false });
    });
  };
  openOrder = () => {
    this.closeStatuses();
    this.props.openOrder();
  };
  render() {
    let { item, statuses } = this.props;
    let {
      openStatuses,
      statusId,
      addClass,
      newStatus,
      loader,
      newStatuses,
    } = this.state;
    return (
      <tr className={classNames.border_bottom}>
        <td onClick={this.openOrder}>{item.id}</td>
        <td onClick={this.openOrder}>
          {moment(item.delivery_date).format("DD.MM.YYYY")}
        </td>
        <td onClick={this.openOrder}>
          {item.delivery_type == "pickup"
            ? "Самовывоз"
            : item.delivery_type == "courier"
            ? "Курьером"
            : ""}
        </td>
        <td onClick={this.openOrder}>{item.payment_type.name}</td>
        <td onClick={this.openOrder}>{item.client_address}</td>
        <td onClick={this.openOrder}>{item.client_phone_number}</td>
        <td
          className={addClass ? "status" : ""}
          onMouseOver={() => {
            this.setState({ addClass: true });
          }}
          onMouseOut={() => {
            this.setState({ addClass: false });
          }}
        >
          <div className={classNames.status}>
            <div
              className={classNames.status}
              style={{ width: "100%" }}
              onClick={this.openStatuses}
            >
              <div>{item.status.name}</div>

              <div className={classNames.arrow}></div>
            </div>
            <div
              className={classNames.icon_status}
              onClick={() => this.props.saveFile(item.id)}
            ></div>

            {openStatuses && (
              <div
                className={
                  classNames.block_statuses +
                  " block_statuses " +
                  (loader ? classNames.load_status : "")
                }
              >
                <Loader
                  loaded={!loader}
                  lines={6}
                  length={5}
                  radius={10}
                ></Loader>
                {newStatuses.map((item) => {
                  return (
                    <div className={classNames.status}>
                      <RadioButton
                        title={item.name}
                        onclick={() => this.choiceStatus(item.id)}
                        checked={newStatus == item.id}
                        disabled={
                          (item.id < statusId && item.id != 4) ||
                          statusId == 4 ||
                          statusId == 7
                        }
                      />
                    </div>
                  );
                  // <div>{item.name}</div>;
                })}
                <div className={classNames.save_status}>
                  <Button
                    title="Изменить"
                    onclick={this.updateStatus}
                    type="yellow"
                    disabled={statusId == 4 || statusId == 7 || loader}
                  />
                </div>
              </div>
            )}
          </div>
        </td>
      </tr>
    );
  }
}

export default RowOrder;
