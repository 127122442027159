import React from "react";
import classNames from "./style.module.scss";

interface Props {
  title: String;
  onclick: any;
  //   style: any;
  type?: String;
  width?: boolean;
  style?: any;
  disabled?: boolean;
}

const Button = ({ title, onclick, type, width, style, disabled }: Props) => {
  // console.log(width);
  return (
    <div
      className={
        (type == "disabled" || disabled
          ? classNames.button_disabled
          : type == "yellow"
          ? classNames.button_open
          : type == "white"
          ? classNames.button_white
          : classNames.button_red) +
        " " +
        (width && classNames.long_button)
      }
      style={style}
      onClick={disabled ? () => {} : onclick}
    >
      <div>{title}</div>
    </div>
  );
};

export default Button;
