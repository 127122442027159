import React, { Component } from "react";
import classNames from "./style.module.scss";

interface Props {
  img?: any;
  addCount: any;
  subCount: any;
  deleteProduct: any;
  item: any;
  change: boolean;
  quantity?: any;
  quantity_count?: number;
}

interface State {
  openPopconfirm: boolean;
  addClass: boolean;
}

class BasketItem extends Component<Props> {
  state: State = {
    openPopconfirm: false,
    addClass: false,
  };

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, false);
  }

  componentWillMount() {
    document.addEventListener("click", this.handleClickOutside, false);
  }

  handleClickOutside = (event: any) => {
    let delete_block = document.getElementsByClassName("delete_block")[0];
    let popconfirm = document.getElementsByClassName("popconfirm")[0];
    // console.log(event.path.includes(delete_block));
    // console.log(event.path.includes(popconfirm));
    if (!event.path.includes(delete_block) && !event.path.includes(popconfirm))
      this.setState({ openPopconfirm: false });
  };

  openDeleteProduct = () => {
    this.setState({ openPopconfirm: !this.state.openPopconfirm });
  };

  render() {
    let {
      item,
      change,
      deleteProduct,
      subCount,
      addCount,
      img,
      quantity,
      quantity_count,
    } = this.props;
    let { openPopconfirm, addClass } = this.state;
    return (
      <div
        className={
          classNames.basket_item + " " + (quantity ? classNames.disabled : "")
        }
      >
        <div className={classNames.flex}>
          <div
            className={classNames.img}
            style={{ backgroundImage: img ? "url(" + img + ")" : "" }}
          ></div>
          <div className={classNames.info}>
            <div className={classNames.item_title}>{item.product.name}</div>
            {
              item.product.code &&
              <div className={classNames.item_code}>{item.product.code}</div>
            }
            <div className={classNames.block_bottom}>
              <div className={classNames.flex}>
                <div className={classNames.price}>{item.price} ₽</div>
                {/* {!change && item.quantity > 1 && (
                  <div style={{ fontSize: 12 }}> x {item.quantity}</div>
                )} */}
              </div>
              <div className={classNames.flex}>
                {change ? (
                  <div
                    className={classNames.flex}
                    style={{ position: "relative" }}
                  >
                    <div
                      className={"delete" + (addClass ? " delete_block" : "")}
                      onClick={() => this.setState({ openPopconfirm: true })}
                      onMouseOver={() => {
                        this.setState({ addClass: true });
                      }}
                      onMouseOut={() => {
                        this.setState({ addClass: false });
                      }}
                    ></div>
                    {openPopconfirm && (
                      <div
                        className={classNames.popconfirm + " popconfirm"}
                      // onMouseOut={() => this.setState({ openPopconfirm: false })}
                      >
                        <div className={classNames.title}>
                          Удалить товар из корзины?
                        </div>
                        <div className={classNames.flex}>
                          <div
                            className={classNames.button_yes}
                            onClick={deleteProduct}
                          >
                            Да
                          </div>
                          <div
                            className={classNames.button_No}
                            onClick={() =>
                              this.setState({ openPopconfirm: false })
                            }
                          >
                            Нет
                          </div>
                        </div>
                      </div>
                    )}
                    <div className={classNames.block_count}>
                      <div
                        className={classNames.operator_minus}
                        onClick={() => subCount()}
                      ></div>
                      <div
                        className={
                          quantity_count && quantity_count < item.quantity
                            ? classNames.deficiency
                            : ""
                        }
                      >
                        {item.quantity}
                      </div>
                      <div
                        className={classNames.operator_add}
                        onClick={() => addCount()}
                      ></div>
                    </div>
                  </div>
                ) : (
                    <div className={classNames.block_quantity}>
                      <div
                        className={
                          quantity_count && quantity_count < item.quantity
                            ? classNames.deficiency
                            : ""
                        }
                      >
                        {item.quantity} шт.
                    </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BasketItem;
