import React from "react";
import { Switch, Route } from "react-router-dom";
import {
  MainPage,
  EmptyPage,
  AuthorizationPage,
  PromotionsPage,
} from "../pages";

const MainRoute = () => {
  return (
    <Switch>
      <Route exact path="/" component={MainPage} />
      <Route exact path="/auth" component={AuthorizationPage} />
      <Route exact path="/promotions" component={PromotionsPage} />
      <Route component={EmptyPage} />
    </Switch>
  );
};

export default MainRoute;
