import React, { Component } from "react";
import classNames from "./style.module.scss";

interface Props {
  price: number;
  prev_price: number;
  name: string;
  img?: string;
  onclickAdd: any;
}
class Product extends Component<Props> {
  render() {
    let { price, prev_price, name, onclickAdd } = this.props;

    return (
      <div className={classNames.product_item} onClick={onclickAdd}>
        <div className={classNames.img}></div>
        <div className={classNames.description}>
          <div className={classNames.title}>{name}</div>
          <div className={classNames.block_price}>
            <div className={classNames.price}>
              {prev_price ? prev_price : price} ₽
            </div>
            {prev_price && prev_price != price && (
              <div className={classNames.previous_price}>{price} ₽</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default Product;
