import React from "react";
import classNames from "./style.module.scss";
import RowOrder from "./RowOrder";
import RowPromotion from "./RowPromotion";

interface Props {
  columns: Array<String>;
  type: String;
  arrayItems: Array<any>;
  open: any;
  statuses?: Array<any>;
  updateStatus?: any;
  saveFile?:any;
}

const Table = ({ columns, type, arrayItems, statuses, ...props }: Props) => {
  return (
    <table className={classNames.table_items}>
      <tr>
        {columns.map((item) => {
          return <th>{item}</th>;
        })}
      </tr>
      {type == "orders"
        ? arrayItems.map((item) => {
            return (
              <RowOrder
                item={item}
                openOrder={() => props.open(item.id)}
                statuses={statuses || []}
                updateStatus={props.updateStatus}
                saveFile={props.saveFile}
              />
            );
          })
        : arrayItems.map((item) => {
            return (
              <RowPromotion item={item} openPromo={() => props.open(item.id)} />
            );
          })}
    </table>
  );
};

export default Table;
