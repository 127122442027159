import React, { ReactNode } from 'react';
import classNames from './style.module.scss';

interface Props {
    children: ReactNode;
}

const PageContent = ({ children }: Props) => {
    return (
        <main className={classNames.container}>
            {children}
        </main>
    )
}

export default PageContent;