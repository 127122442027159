import React, { Component } from "react";
import { Button } from "../../ui";
import { RouteComponentProps } from "react-router-dom";
import classNames from "./style.module.scss";
import * as request from "../../requests";
interface State {
  email: String;
  password: String;
  error: boolean;
}
class AuthorizationPage extends React.Component<RouteComponentProps<any>, any> {
  state: State = {
    email: "",
    password: "",
    error: false,
  };
  entry = () => {
    let { email, password } = this.state;
    request
      .auth({ email, password })
      .then((json: any) => {
        console.log(json);
        localStorage.setItem("token_penny", json.token);
        localStorage.setItem("user_id_penny", json.user_id);
        this.props.history.push("/");
      })
      .catch(() => {
        this.setState({ error: true });
      });
  };

  changeInput = (event: React.FormEvent<HTMLInputElement>) => {
    let field = event.currentTarget.name;
    let value = event.currentTarget.value;
    if (field == "email") this.setState({ email: value });
    else this.setState({ password: value });
  };

  render() {
    return (
      <div className={classNames.container}>
        <div className={classNames.block_auth}>
          <div className={classNames.title}>Вход</div>
          <div className="item_title">Почта</div>
          <input name="email" onChange={this.changeInput}></input>
          <div className="item_title">Пароль</div>
          <input
            name="password"
            type="password"
            onChange={this.changeInput}
          ></input>
          {this.state.error && (
            <div className={classNames.error}>Неверный логин или пароль</div>
          )}
          <Button type="yellow" title="Войти" onclick={this.entry} width />
        </div>
      </div>
    );
  }
}

export default AuthorizationPage;
