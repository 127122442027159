import React, { Component } from "react";
import classNames from "./style.module.scss";
import { Button } from "../../ui";
import * as request from "../../requests";
import Loader from "react-loader";

interface Props {
  // onCreate: any;
  onClose: any;
  // openMap: any;
  store_id: string;
  id: number;
}

interface State {
  couriers: Array<any>;
  courier: any;
  loader: boolean;
}

class CourierModal extends Component<Props> {
  state: State = {
    couriers: [],
    courier: {},
    loader: true,
  };

  componentDidMount() {
    request.getCouriers(this.props.store_id).then((json: any) => {
      console.log(json);
      this.setState({ couriers: json.data, loader: false });
    });
  }

  selectionCourier = () => {
    let courier = this.state.courier;
    let params = {
      status: {
        status_id: 5,
        description: "",
      },
      courier_id: courier.id,
    };
    request.updateOrder(this.props.id, params).then(() => {
      this.props.onClose();
    });
  };

  clickCourier = (courier: any) => {
    this.setState({ courier });
  };

  render() {
    let { couriers, courier, loader } = this.state;
    return (
      <div className={classNames.container_courier}>
        <Loader loaded={!loader} lines={8} length={5} radius={15}>
          <div className={classNames.couriers_block}>
            <div className={classNames.flex + " " + classNames.top}>
              <div className={classNames.title_top}>Выбор курьера</div>
              <div
                className={classNames.close}
                onClick={this.props.onClose}
              ></div>
            </div>
            <div className={classNames.couriers}>
              {couriers.map((item) => {
                return (
                  <div
                    className={
                      classNames.courier_item +
                      " " +
                      (item.id == courier.id ? classNames.selected_courier : "")
                    }
                    onClick={() => this.clickCourier(item)}
                  >
                    <div className={classNames.courier_block}>{item.name}</div>
                  </div>
                );
              })}
            </div>
            <div style={{ width: "90%", padding: "0 16px" }}>
              <Button
                title="Выбрать курьера"
                type="yellow"
                onclick={this.selectionCourier}
                disabled={!courier.id}
                width
              />
            </div>
          </div>
        </Loader>
      </div>
    );
  }
}

export default CourierModal;
