import React, { Component } from "react";
import { Link } from "react-router-dom";
import classNames from "./style.module.scss";

interface Props {
  exit: any;
}
class Header extends Component<Props> {
  render() {
    const user_id = localStorage.getItem("user_id_penny");
    return (
      <div className={classNames.container}>
        <div className={classNames.left_block}>
          <div className={classNames.img}>
            <img
              className={classNames.logo}
              src={require("../../assets/images/logo.png")}
            />
          </div>
          <Link to={"/"}>Заказы</Link>
          {user_id == "1" && <Link to={"/promotions"}>Акции</Link>}
        </div>
        <div className={classNames.right_block}>
          <div className={classNames.exit_logo}></div>
          <div className={classNames.exit} onClick={() => this.props.exit()}>
            Выйти
          </div>
        </div>
      </div>
    );
  }
}
export default Header;
