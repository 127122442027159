import React, { Component } from "react";

import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import classNames from "./style.module.scss";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "../../ui";
import moment from "moment";

interface Props {
  title: String;
  addPromotion?: any;
  changeDate?: any;
  onSearch?: any;
  update?: any;
  noDate?: boolean;
}

interface State {
  date_start: string;
  date_end: string;
  search: string;
}
class FilterTable extends Component<Props> {
  state: State = {
    date_start: moment().format("YYYY-MM-DD"),
    date_end: moment().format("YYYY-MM-DD"),
    search: "",
  };

  private timer: any;

  changePeriod = (type: number, date: Date) => {
    if (type == 1)
      this.setState({ date_start: moment(date).format("YYYY-MM-DD") }, () =>
        this.props.changeDate(
          moment(this.state.date_start)
            .startOf("day")
            .format("YYYY-MM-DD HH:mm"),
          moment(this.state.date_end).endOf("day").format("YYYY-MM-DD HH:mm")
        )
      );
    else
      this.setState(
        { date_end: moment(date).endOf("day").format("YYYY-MM-DD HH:mm") },
        () =>
          this.props.changeDate(
            moment(this.state.date_start)
              .startOf("day")
              .format("YYYY-MM-DD HH:mm"),
            moment(this.state.date_end).endOf("day").format("YYYY-MM-DD HH:mm")
          )
      );
  };

  changeSearch = (event: React.FormEvent<HTMLInputElement>) => {
    clearTimeout(this.timer);

    let value = event.currentTarget.value;

    this.setState({ search: value }, () => {
      this.timer = setTimeout(() => {
        console.log(value);
        this.props.onSearch(this.state.search);
      }, 1000);
    });
  };
  // addPromotion = () => {};
  render() {
    let { title, noDate } = this.props;
    let { date_start, date_end } = this.state;
    return (
      <div className={classNames.filter_table}>
        <div className={classNames.block_left}>
          <div className={classNames.title_table}>{title}</div>
          {
            !noDate &&
            <React.Fragment>
              <div className={classNames.period}>От</div>
              <DatePicker
                value={date_start}
                onChange={(date: Date) => this.changePeriod(1, date)}
                locale={ru}
                // placeholderText="Выберите дату"
                dateFormat="dd.MM.yyyy"
              />
              <div className={classNames.period}>До</div>
              <DatePicker
                value={date_end}
                onChange={(date: Date) => this.changePeriod(2, date)}
                locale={ru}
                // placeholderText="Выберите дату"
                dateFormat="dd.MM.yyyy"
              />
            </React.Fragment>
          }
          {title == "Заказы" && (
            <Button
              title="Обновить"
              type="yellow"
              onclick={this.props.update}
              style={{
                padding: "6px 30px",
                fontSize: 14,
              }}
            />
          )}
        </div>
        <div className={classNames.flex}>
          <div className={classNames.block_right}>
            <input placeholder={"Поиск"} onChange={this.changeSearch}></input>
            <img
              className={classNames.icon}
              src={require(`../../assets/images/search-icon-black.svg`)}
            />
          </div>
          {title == "Акции" && (
            <Button
              title="Добавить акцию"
              type="yellow"
              onclick={this.props.addPromotion}
              style={{
                padding: "6px 20px",
                fontSize: 14,
                margin: 0,
                marginLeft: 10,
              }}
            />
          )}
        </div>
      </div>
    );
  }
}
export default FilterTable;
