import React, { Component } from "react";
import classNames from "./style.module.scss";
import { Button } from "../../../ui";
import SearchBar from "../Searchbar/Searchbar";
import Product from "../../Product/Product";
import * as request from "../../../requests";

interface Props {
  addItem: any;
}

interface State {
  products: Array<any>;
  search: string;
}

class BasketAddModal extends Component<Props> {
  state: State = {
    products: [],
    search: "",
  };
  private timer: any;

  componentDidMount() {
    request.getProducts().then((json: any) => {
      this.setState({ products: json.data.data });
    });
  }
  changeSearch = (event: React.FormEvent<HTMLInputElement>) => {
    clearTimeout(this.timer);

    let value = event.currentTarget.value;

    this.setState({ search: value }, () => {
      this.timer = setTimeout(() => {
        console.log(value);
        request.getProducts({ search: value }).then((json: any) => {
          this.setState({ products: json.data.data });
        });
      }, 1000);
    });
  };
  onClickSearch = () => {};

  onclickAddProduct = (item: any) => {
    this.props.addItem(item);
  };

  render() {
    let { products } = this.state;
    return (
      <div className={classNames.container}>
        <SearchBar
          onChange={this.changeSearch}
          onClickSearch={this.onClickSearch}
          value=""
        />
        <div className={classNames.items}>
          {products.map((item) => {
            return (
              <Product
                name={item.name}
                price={item.price}
                prev_price={item.price_discount}
                onclickAdd={() => this.onclickAddProduct(item)}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default BasketAddModal;
