import { request } from "./fetch";

const auth = (params: any) => {
  return new Promise((resolve, reject) => {
    request
      .post("manager/auth", params)
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

const getCouriers = (store_id: string) => {
  return new Promise((resolve, reject) => {
    request
      .get("manager/couriers?store_id=" + store_id)
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

const getPromotions = (page: number, date?: string, search?: string) => {
  let url = "manager/promotions?page=" + page;
  // if (date) url += "&date=" + date;
  if (search) url += "&search=" + search;
  return new Promise((resolve, reject) => {
    request
      .get(url)
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

const getPromotion = (id: Number) => {
  return new Promise((resolve, reject) => {
    request
      .get("manager/promotions/" + id)
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

const updatePromotion = (id: Number, params: any) => {
  return new Promise((resolve, reject) => {
    request
      .put("manager/promotions/" + id, params)
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

const createPromotion = (params: any) => {
  return new Promise((resolve, reject) => {
    request
      .post("manager/promotions", params)
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

const deletePromotion = (id: Number) => {
  return new Promise((resolve, reject) => {
    request
      .delete("manager/promotions/" + id)
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

const imageUpload = (params: any) => {
  return new Promise((resolve, reject) => {
    request
      .post(`manager/images`, params)
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

const deleteImage = (params: any) => {
  return new Promise((resolve, reject) => {
    request
      .delete("manager/images", params)
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

const getStatuses = () => {
  return new Promise((resolve, reject) => {
    request
      .get("manager/statuses")
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

const getOrders = (page?: number, date?: string, search?: string) => {
  return new Promise((resolve, reject) => {
    let url = "manager/orders";
    if (page || date) url += "?";
    if (page) url += "page=" + page;
    if (date) url += (page ? "&" : "") + "date=" + date;
    if (search) url += (page ? "&" : "") + "search=" + search;
    request
      .get(url)
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

const getOrder = (id: number) => {
  return new Promise((resolve, reject) => {
    request
      .get("manager/orders/" + id)
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

const updateOrder = (id: number, params: any) => {
  return new Promise((resolve, reject) => {
    request
      .put("manager/orders/" + id, params)
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

const getStores = (
  type = false,
  page = 1,
  region_id = "",
  latitude?: number,
  longitude?: number
) => {
  return new Promise((resolve, reject) => {
    let url = "stores";

    if (!type) {
      url += "?page=" + page;
      if (region_id.length != 0) url += "&region_id=" + region_id;
      if (latitude && latitude != 0) url += "&latitude=" + latitude;
      if (longitude && longitude != 0) url += "&longitude=" + longitude;
    }
    request
      .get(url)
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

const getProducts = (params?: any) => {
  return new Promise((resolve, reject) => {
    request
      .post("assortments", params)
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

const getBaskets = (params: any) => {
  return new Promise((resolve, reject) => {
    request
      .post("baskets", params)
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

const getFile= (id: number) => {
return new Promise((resolve, reject) => {
  request
    .get("manager/files/"+id)
    .then((json) => resolve(json))
    .catch((error) => reject(error));
});
};

export {
  auth,
  getCouriers,
  getPromotions,
  getPromotion,
  updatePromotion,
  createPromotion,
  deletePromotion,
  imageUpload,
  deleteImage,
  getStatuses,
  getOrders,
  getOrder,
  updateOrder,
  getStores,
  getProducts,
  getBaskets,
  getFile
};
